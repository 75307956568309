import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Card } from 'components/ui';

function Offers(props) {
  const { data } = props;
  return (
    <Container maxWidth={false}>
      <div className="spaced-grid-wrapper">
        <Grid container spacing={8}>
          {data.map((item, key) => {
            return (
              <Grid item xs={12} md={6} lg={4} key={key}>
                <Card data={item} />
              </Grid>
            );
          })}
        </Grid>
      </div>
    </Container>
  );
}

Offers.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Offers;
