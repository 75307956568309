import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  wrapper: {
    minHeight: 'auto',
    [theme.breakpoints.up('md')]: {
      height: '590px',
    },
  },
  container: {
    height: '100% !important',
  },
  halfbox: {
    height: '300px',
    width: '100%',
    marginBottom: '16px',
    [theme.breakpoints.up('md')]: {
      height: '100%',
    },
  },
  fullbox: {
    height: '300px',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      height: '100%',
    },
  },
  marginBtn: {
    marginBottom: '16px',
  },
  prMobile: {
    [theme.breakpoints.down('md')]: {
      paddingRight: '8px',
    },
  },
  plMobile: {
    [theme.breakpoints.down('md')]: {
      paddingLeft: '8px',
    },
  },
});

export const Background = styled.div`
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: ${props => `url(${props.image})`};
`;

function ImageGrid(props) {
  const { data, classes } = props;
  const doc = data.slice(0, 1).pop();
  if (!doc) return null;
  return (
    <Box className={classes.wrapper}>
      <div className={`${classes.container} spaced-grid-wrapper`}>
        <Grid container className={classes.container} spacing={2}>
          <Grid item xs={12} md={3}>
            <Grid container className={classes.container}>
              {doc.tile1_image && (
                <Grid item xs={12} className={classes.marginBtn}>
                  <Background
                    className={classes.halfbox}
                    image={doc.tile1_image.url}
                  />
                </Grid>
              )}
              {doc.tile2_image && (
                <Grid item xs={12}>
                  <Background
                    className={classes.halfbox}
                    image={doc.tile2_image.url}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} md={3}>
            <Grid container className={classes.container}>
              {doc.tile3_image && (
                <Grid item xs={12} className={classes.marginBtn}>
                  <Background
                    className={classes.halfbox}
                    image={doc.tile3_image.url}
                  />
                </Grid>
              )}
              {doc.tile4_image && (
                <Grid item xs={12}>
                  <Background
                    className={classes.halfbox}
                    image={doc.tile4_image.url}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Box component={Grid} order={{ xs: 2, md: 1 }} item xs={12} md={3}>
            <Grid container className={classes.container}>
              {doc.tile5_image && (
                <Box
                  component={Grid}
                  item
                  xs={12}
                  md={12}
                  mb={2}
                  pr={0}
                  className={classes.marginBtn}
                >
                  <Background
                    className={classes.halfbox}
                    image={doc.tile5_image.url}
                  />
                </Box>
              )}
              {doc.tile6_image && (
                <Box component={Grid} item xs={12} md={12} pl={0}>
                  <Background
                    className={classes.halfbox}
                    image={doc.tile6_image.url}
                  />
                </Box>
              )}
            </Grid>
          </Box>
          {doc.tile7_image && (
            <Box component={Grid} order={{ xs: 1, md: 2 }} item xs={12} md={3}>
              <Background
                className={classes.fullbox}
                image={doc.tile7_image.url}
              />
            </Box>
          )}
        </Grid>
      </div>
    </Box>
  );
}

ImageGrid.propTypes = {
  data: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ImageGrid);
