import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';

const styles = theme => ({
  wrapper: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '10px',
  },
  input: {
    '-webkit-appearance': 'none !important',
    '-moz-appearance': 'textfield !important',
    margin: 0,
    padding: '10px',
    height: '50px',
    width: '250px',
    textAlign: 'center',
    borderRadius: '5px',
    outline: 0,
    border: `3px solid ${theme.palette.primary.main}`,
    fontSize: '2rem',
    '&:hover, &:focus, &::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none !important',
      '-moz-appearance': 'textfield !important',
    },
  },
});

function HelpToBuy(props) {
  const { data, classes } = props;
  const middle =
    Math.floor(data.max_price - data.min_price) / 2 + data.min_price;
  const [value, setValue] = useState(middle);
  const [readable, setReadable] = useState(middle.toLocaleString());
  const [deposit, setDeposit] = useState(
    Math.floor((middle / 100) * data.deposit_percentage)
  );
  const [equityloan, setEquityloan] = useState(
    Math.floor((middle / 100) * data.equity_loan_percentage)
  );
  const [mortgage, setMortgage] = useState(
    Math.floor((middle / 100) * data.mortgage_percentage)
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setReadable(newValue.toLocaleString());
    setDeposit(Math.floor((newValue / 100) * data.deposit_percentage));
    setEquityloan(Math.floor((newValue / 100) * data.equity_loan_percentage));
    setMortgage(Math.floor((newValue / 100) * data.mortgage_percentage));
  };
  const handleInputChange = event => {
    setValue(event.target.value);
    setReadable(event.target.value.toLocaleString());
    setDeposit(
      Math.floor((event.target.value / 100) * data.deposit_percentage)
    );
    setEquityloan(
      Math.floor((event.target.value / 100) * data.equity_loan_percentage)
    );
    setMortgage(
      Math.floor((event.target.value / 100) * data.mortgage_percentage)
    );
  };
  return (
    <Container maxWidth="lg">
      <Box className={classes.wrapper} p={[2, 5, 10]}>
        <Box>
          <Typography component="p" variant="h5">
            {data.title[0].text}
          </Typography>
        </Box>
        <Box py={5}>
          <Grid container spacing={4}>
            <Grid item xs>
              <Typography component="p" variant="subtitle2" align="center">
                MIN
              </Typography>
              <Typography component="p" variant="subtitle1" align="center">
                £{data.min_price.toLocaleString()}
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Slider
                className={classes.slider}
                value={value}
                min={data.min_price}
                max={data.max_price}
                onChange={handleChange}
                aria-labelledby="input-slider"
              />
            </Grid>
            <Grid item xs>
              <Typography component="p" variant="subtitle2" align="center">
                MAX
              </Typography>
              <Typography component="p" variant="subtitle1" align="center">
                £{data.max_price.toLocaleString()}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center">
          <input
            type="text"
            value={`£${readable}`}
            min={data.min_price}
            max={data.max_price}
            minLength={data.min_price.length}
            onChange={handleInputChange}
            className={classes.input}
            disabled
          />
        </Box>
        <Box py={10}>
          <Typography component="p" variant="h6" align="center">
            Your purchase could be made up of
          </Typography>
        </Box>
        <Box component={Grid} container px={[0, 0, 10]} mb={4}>
          <Grid xs={4}>
            <Typography component="p" variant="h6">
              Deposit
            </Typography>
          </Grid>
          <Grid xs={4}>
            <Typography component="p" variant="h6" align="center">
              £{deposit.toLocaleString()}
            </Typography>
          </Grid>
          <Grid xs={4}>
            <Typography component="p" variant="h6" align="right">
              {data.deposit_percentage}%
            </Typography>
          </Grid>
        </Box>
        <Box component={Grid} container px={[0, 0, 10]} mb={4}>
          <Grid xs={4}>
            <Typography component="p" variant="h6">
              Equity Loan
            </Typography>
          </Grid>
          <Grid xs={4}>
            <Typography component="p" variant="h6" align="center">
              £{equityloan.toLocaleString()}
            </Typography>
          </Grid>
          <Grid xs={4}>
            <Typography component="p" variant="h6" align="right">
              {data.equity_loan_percentage}%
            </Typography>
          </Grid>
        </Box>
        <Box component={Grid} container px={[0, 0, 10]}>
          <Grid xs={4}>
            <Typography component="p" variant="h6">
              Mortgage
            </Typography>
          </Grid>
          <Grid xs={4}>
            <Typography component="p" variant="h6" align="center">
              £{mortgage.toLocaleString()}
            </Typography>
          </Grid>
          <Grid xs={4}>
            <Typography component="p" variant="h6" align="right">
              {data.mortgage_percentage}%
            </Typography>
          </Grid>
        </Box>
        <Box pt={10}>
          <Typography component="p" variant="caption" align="center">
            {data.text[0].text}
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}

HelpToBuy.propTypes = {
  data: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HelpToBuy);
