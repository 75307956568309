import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Logo from 'images/Hayfield-Wordmark.svg';
import { LocationMap } from 'components/slices';
import { ButtonBlockDark } from 'components/ui';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  logo: {
    width: '80px',
    [theme.breakpoints.up('lg')]: {
      width: '100px',
    },
  },
  input: {
    height: '40px',
    width: 'auto',
    minWidth: '200px',
    padding: '10px',
    border: `1px solid ${theme.palette.primary.main}`,
    outline: 0,
    textTransform: 'uppercase',
    '&:focus': {
      outline: 0,
    },
  },
  btn: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    border: `3px solid ${theme.palette.primary.main}`,
    background: theme.palette.primary.main,
    color: theme.palette.text.white,
    textTransform: 'uppercase',
    textDecoration: 'none',
    boxShadow: `inset 0px 0px 0px 0px ${theme.palette.text.white}`,
    transition: 'box-shadow 0.2s ease-in-out',
    height: '40px',
    padding: '0 20px',
    '&:hover': {
      boxShadow: `inset 0px 0px 0px 3px ${theme.palette.text.white}`,
    },
  },
});

function Location(props) {
  const {
    classes,
    title,
    data,
    geolocation,
    locationPostcode,
    localareaguide,
  } = props;
  const [postcode, setPostcode] = useState('');
  return (
    <Grid container>
      <Box
        component={Grid}
        item
        xs={12}
        md={6}
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        mb={[4, 4, 0]}
      >
        <Logo className={classes.logo} />
        <Typography component="h3" variant="h3">
          {title}
        </Typography>
        <Box py={4} display="flex" alignItems="center" flexDirection="column">
          <Typography component="p" variant="subtitle1">
            {data.address_line_1 ? data.address_line_1[0].text : null}
          </Typography>
          <Typography component="p" variant="subtitle1">
            {data.address_line_2 ? data.address_line_2[0].text : null}
          </Typography>
          <Typography component="p" variant="subtitle1">
            {data.town ? data.town[0].text : null}
          </Typography>
          <Typography component="p" variant="subtitle1">
            {data.county ? data.county[0].text : null}
          </Typography>
          <Typography component="p" variant="subtitle1">
            {data.postcode ? data.postcode[0].text : null}
          </Typography>
        </Box>
        {localareaguide && (
          <Box>
            <ButtonBlockDark
              text="DONWLOAD LOCAL AREA GUIDE"
              link={localareaguide}
            />
          </Box>
        )}
        <Box mt={4} display="flex">
          <Box>
            <input
              type="text"
              placeholder="Enter your postcode"
              value={postcode}
              onChange={e => setPostcode(e.target.value)}
              className={classes.input}
            />
          </Box>
          <Box>
            <a
              href={`https://www.google.com/maps?saddr=${postcode}&daddr=${locationPostcode}`}
              target="_blank"
              rel="nofollow noreferrer"
              className={classes.btn}
            >
              <Typography component="span" variant="button">
                GET DIRECTIONS
              </Typography>
            </a>
          </Box>
        </Box>
      </Box>
      <Grid item xs={12} md={6}>
        <LocationMap data={geolocation} />
      </Grid>
    </Grid>
  );
}

Location.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  geolocation: PropTypes.object.isRequired,
  locationPostcode: PropTypes.string,
  localareaguide: PropTypes.string,
};

export default withStyles(styles)(Location);
