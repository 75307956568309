import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

function FullImage(props) {
  const { data } = props;
  return (
    <Box
      component="img"
      width="100%"
      src={`${data.image.url}&q=10&lossless=1`}
      alt={data.image.alt}
    />
  );
}

FullImage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default FullImage;
