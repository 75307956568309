import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import ReactPlayer from 'react-player';
import { withStyles } from '@material-ui/styles';

const styles = {
  playerWrapper: {
    position: 'relative',
    paddingTop: '56.25%',
  },
  reactPlayer: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
};

function Video(props) {
  const { data, classes } = props;
  return (
    <Container maxWidth="xl">
      <div className={classes.playerWrapper}>
        <ReactPlayer
          className={classes.reactPlayer}
          url={data.video_url?.embed_url}
          controls={true}
          width="100%"
          height="100%"
        />
      </div>
    </Container>
  );
}

Video.propTypes = {
  data: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Video);
