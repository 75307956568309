import React from 'react';
import PropTypes from 'prop-types';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import { RichText } from 'prismic-reactjs';
import { ButtonBlockDark, ButtonBlockDarkExternal } from 'components/ui';
import linkResolver from 'utils/linkResolver';

const styles = theme => ({
  image: {
    maxWidth: '100%',
  },
  title: {
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
    },
  },
  body: {
    fontSize: '1rem',
    letterSpacing: '0.75px',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
    },
  },
  cta: {
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
    },
  },
});

function V2TextLeftImageRight(props) {
  const { data, classes } = props;
  return (
    <Container maxWidth={false}>
      <Grid container alignItems="center">
        <Box component={Grid} item xs={12} md={6} mb={[4, 4, 0]}>
          <Box pr={[0, 2, 2, 16]}>
            <Grid container justify="center">
              <Grid item>
                <Box
                  display="flex"
                  justifyContent="center"
                  flexDirection="column"
                >
                  <Typography
                    component="h4"
                    variant="h4"
                    color="textPrimary"
                    gutterBottom
                    className={classes.title}
                  >
                    {data.title[0].text}
                  </Typography>
                  <Typography
                    component="span"
                    variant="subtitle2"
                    className={classes.body}
                  >
                    <RichText render={data.text} />
                  </Typography>
                  {data.link_title && data.link && (
                    <Box className={classes.cta}>
                      {data.link.__typename != 'PRISMIC__ExternalLink' && (
                        <ButtonBlockDark
                          text={data.link_title[0].text}
                          link={
                            data.link._meta ? linkResolver(data.link._meta) : ''
                          }
                        />
                      )}
                      {data.link.__typename == 'PRISMIC__ExternalLink' && (
                        <ButtonBlockDarkExternal
                          text={data.link_title[0].text}
                          link={data.link.url}
                        />
                      )}
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Grid item xs={12} md={6}>
          <img
            className={classes.image}
            src={`${data.image.url}&q=10&lossless=1`}
            alt={data.image.alt}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

V2TextLeftImageRight.propTypes = {
  data: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(V2TextLeftImageRight);
