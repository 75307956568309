import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import { ButtonBlockDark, ButtonBlockDarkExternal } from 'components/ui';
import linkResolver from 'utils/linkResolver';

const styles = theme => ({
  bgImageWrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '40px',
    minHeight: '150px',
    height: 'auto',
    [theme.breakpoints.up('lg')]: {
      height: '385px',
    },
  },
});

export const Background = styled.div`
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: ${props => `url(${props.image})`};
`;

function WidescreenImageBlock(props) {
  const { data, fields, classes } = props;
  return (
    <Container maxWidth={false}>
      <Background className={classes.bgImageWrapper} image={data.image.url}>
        {data.title && (
          <Box>
            <Typography component="p" variant="h4" color="secondary" align="center">
              {data.title[0].text}
            </Typography>
          </Box>
        )}
        {fields && fields[0] && fields[0].link_title && fields[0].link && (
          <Box pt={data.title ? 6 : 0} display="flex" flexDirection="row">
            {fields.map((item, key) => (
              <React.Fragment key={key}>
                {item.link_title && item.link && (
                  <Box px={4}>
                    {item.link.__typename != 'PRISMIC__ExternalLink' && (
                      <ButtonBlockDark
                        text={item.link_title[0].text}
                        link={
                          item.link._meta ? linkResolver(item.link._meta) : ''
                        }
                      />
                    )}
                    {item.link.__typename == 'PRISMIC__ExternalLink' && (
                      <ButtonBlockDarkExternal
                        text={item.link_title[0].text}
                        link={item.link.url}
                      />
                    )}
                  </Box>
                )}
              </React.Fragment>
            ))}
          </Box>
        )}
      </Background>
    </Container>
  );
}

WidescreenImageBlock.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
};

export default withStyles(styles)(WidescreenImageBlock);
