import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { RichText } from 'prismic-reactjs';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  body: {
    fontSize: '1rem',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
    },
  },
});

function TwoColumnText(props) {
  const { classes, data } = props;
  return (
    <Container maxWidth={false}>
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <div className="spaced-grid-wrapper">
            <Grid container justify="space-between">
              <Box component={Grid} item xs={12} md mb={{ xs: 4, md: 0 }}>
                <Typography
                  component="p"
                  variant="caption"
                  className={classes.body}
                >
                  {RichText.asText(data.text_first_column)}
                </Typography>
              </Box>
              <Grid item xs={1} />
              <Grid item xs={12} md>
                <Typography
                  component="p"
                  variant="caption"
                  className={classes.body}
                >
                  {RichText.asText(data.text_second_column)}
                </Typography>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}

TwoColumnText.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

export default withStyles(styles)(TwoColumnText);
