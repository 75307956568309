import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Card, Tag } from 'components/ui';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  btn: {
    background: 'transparent',
    border: 0,
    padding: 0,
    margin: 0,
    outline: 0,
    cursor: 'pointer',
    marginTop: '10px',
    marginBottom: '10px',
    [theme.breakpoints.up('md')]: {
      marginTop: '0px',
      marginBottom: '0px',
    },
    '&.active': {
      '& .tag': {
        background: theme.palette.primary.main,
      },
    },
    '&:hover': {
      '& .tag': {
        background: theme.palette.primary.main,
      },
    },
  },
  divider: {
    borderBottom: `1px solid ${theme.palette.background.border}`,
  },
  line: {
    height: '1px',
    background: theme.palette.background.border,
  },
  showMoreWrapper: {
    background: theme.palette.secondary.main,
  },
  showMoreBtn: {
    border: `1px solid ${theme.palette.primary.main}`,
    background: 'transparent',
    cursor: 'pointer',
    padding: '5px 20px',
    transition: 'box-shadow 0.2s ease-in-out',
    [theme.breakpoints.up('md')]: {
      padding: '15px 42px',
    },
    '&:hover': {
      boxShadow: `inset 0px 0px 0px 3px ${theme.palette.primary.main}`,
    },
  },
  uppercase: {
    textTransform: 'uppercase',
  },
});

function Articles(props) {
  const { data, classes } = props;
  const increment = 10;
  const [alltags, setAlltags] = useState([]);
  const [dataList, setDataList] = useState(data);
  const [activeTag, setActiveTag] = useState('all');
  const [showNum, setShowNum] = useState(increment);
  // show more
  const showMore = () => {
    setShowNum(showNum + increment);
  };

  // Action filter
  const filterDisplay = tag => {
    setActiveTag(tag);
    let newDataList = data.filter(item => {
      var includeItem = false;
      {
        item.article.content_tagging.map(object => {
          if (object.tag) {
            if (object.tag[0].text == tag) {
              includeItem = true;
            }
          }
        });
      }
      tag == 'all' ? (includeItem = true) : null;
      return includeItem;
    });
    setDataList(newDataList);
  };

  // List all filter tags
  /*const updateAllTags = () => {
    const tags = [];
    data.map(item => {
      item.article.content_tagging.map(item => {
        if (item.tag) {
          if (item.tag.length > 0) {
            tags.push(item.tag[0].text);
          }
        }
      });
    });
    const uniqueSet = tags.filter(
      (item, index) => tags.indexOf(item) === index
    );
    setAlltags(uniqueSet);
  };*/

  useEffect(() => {
    //updateAllTags();
  }, []);
  return (
    <Container maxWidth={false}>
      <div className="spaced-grid-wrapper">
        <Grid container spacing={8}>
          {dataList.map((item, key) => {
            if (key++ < showNum) {
              return (
                <Grid item xs={12} md={6} lg={4} key={key}>
                  <Card data={item.article} contentReference={true} />
                </Grid>
              );
            }
          })}
        </Grid>
      </div>
      {dataList.length > showNum && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="row"
          pt={10}
        >
          <Box className={classes.line} flexGrow="1" />
          <Box className={classes.showMoreWrapper}>
            <button
              aria-label="Show more"
              onClick={() => showMore()}
              className={classes.showMoreBtn}
            >
              <Typography
                component="span"
                variant="button"
                color="textPrimary"
                className={classes.uppercase}
              >
                Show More
              </Typography>
            </button>
          </Box>
          <Box className={classes.line} flexGrow="1" />
        </Box>
      )}
    </Container>
  );
}

Articles.propTypes = {
  data: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Articles);
