import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';

import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';

const styles = theme => ({
  wrapper: {
    backgroundColor: theme.palette.primary.main,
  },
  slider: {
    '&.awssld': {
      '--content-background-color': 'transparent',
      '--slider-height-percentage': '350px',
    },
  },
  uppercase: {
    textTransform: 'uppercase',
  },
});

const convertDate = date => {
  var ret = new Date(date);
  const month = ret.toLocaleString('default', { month: 'long' });
  const year = ret.getFullYear();
  return month + ' ' + year;
};

function Testimonials(props) {
  const { data, fields, classes } = props;
  return (
    <Box
      className={classes.wrapper}
      py={8}
      display="flex"
      alignItems="center"
      flexDirection="column"
    >
      <Container maxWidth={false}>
        <Typography
          component="h3"
          variant="h3"
          color="secondary"
          gutterBottom
          align="center"
        >
          {data.title[0].text}
        </Typography>
        <Typography
          component="p"
          variant="subtitle1"
          color="secondary"
          align="center"
        >
          {data.caption[0].text}
        </Typography>
        <AwesomeSlider className={classes.slider} bullets={false}>
          {fields.map((item, key) => (
            <Box key={key}>
              <Box
                component={Container}
                display="flex"
                alignItems="center"
                flexDirection="column"
              >
                <Box px={[0, 8, 10]}>
                  <Typography
                    component="p"
                    variant="body2"
                    color="secondary"
                    align="center"
                  >
                    {item.testimonial[0].text}
                  </Typography>
                  <Box pt={4}>
                    <Typography
                      component="p"
                      variant="h6"
                      color="secondary"
                      align="center"
                    >
                      {item.customer_name[0].text}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}
        </AwesomeSlider>
      </Container>
    </Box>
  );
}

Testimonials.propTypes = {
  data: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Testimonials);
