import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { ButtonBlockDark } from 'components/ui';
import { withStyles } from '@material-ui/styles';

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const styles = theme => ({
  contactItem: {
    textTransform: 'none',
    letterSpacing: '0.75px',
    listStyle: 'none',
    '&:first-of-type': {
      paddingRight: '2rem',
      [theme.breakpoints.down('md')]: {
        paddingRight: '0',
      },
    },
  },
  underline: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    transition: 'color 0.3s ease-in-out',
    '&:hover': {
      color: theme.palette.text.lblue,
      textDecoration: 'underline',
    },
  },
});

function RegisterInterest(props) {
  const { classes, telephone, email } = props;
  return (
    <Grid container>
      <Grid item xs={12} md={8}>
        <Typography component="p" variant="body2">
          Register your interest
        </Typography>
        <Box
          component="ul"
          p={0}
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
        >
          {telephone && (
            <Typography
              component="li"
              variant="subtitle1"
              className={classes.contactItem}
            >
              T –{' '}
              <Box component="span" className={classes.underline}>
                <a
                  className={classes.link}
                  href={`tel:${telephone}`}
                  rel="noreferrer nofollow"
                  target="_blank"
                >
                  {telephone}
                </a>
              </Box>
            </Typography>
          )}
          <Typography
            component="li"
            variant="subtitle1"
            className={classes.contactItem}
          >
            E –{' '}
            <Box component="span" className={classes.underline}>
              <a
                className={classes.link}
                href={`tel:${email}`}
                rel="noreferrer nofollow"
                target="_blank"
              >
                {email}
              </a>
            </Box>
          </Typography>
        </Box>
      </Grid>
      <Box
        component={Grid}
        item
        xs={12}
        md={4}
        display="flex"
        justifyContent={{ xs: 'flex-start', md: 'flex-end' }}
        alignItems="flex-start"
      >
        <ButtonBlockDark
          text="MAKE AN ENQUIRY"
          link={`/${process.env.contact_path}#form`}
          icon={<ArrowForwardIosIcon />}
        />
      </Box>
    </Grid>
  );
}

RegisterInterest.propTypes = {
  classes: PropTypes.object.isRequired,
  telephone: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
};

export default withStyles(styles)(RegisterInterest);
