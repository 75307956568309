import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Spacer from 'components/spacer';
import { withStyles } from '@material-ui/styles';
import linkResolver from 'utils/linkResolver';

import Lookup from 'helpers/crmExport';

import axios from 'axios';
var parseString = require('xml2js').parseString;

const styles = theme => ({
  uppercase: {
    textTransform: 'uppercase',
  },
  lowercase: {
    textTransform: 'capitalize',
  },
  wrapper: {
    border: '0px',
    [theme.breakpoints.up('lg')]: {
      borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
  },
  header: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
  item: {
    border: `1px solid ${theme.palette.primary.main}`,
    [theme.breakpoints.up('lg')]: {
      borderTop: '0px',
      borderLeft: '0px',
      borderRight: '0px',
      borderBottom: '1px solid rgba(31, 50, 66, 0.3)',
    },
  },
  lastItem: {
    border: `1px solid ${theme.palette.primary.main}`,
    [theme.breakpoints.up('lg')]: {
      border: '0px',
    },
  },
  reduceLetterSpacing: {
    letterSpacing: '0.75px',
  },
  link: {
    textDecoration: 'underline',
    color: theme.palette.primary.main,
  },
  title: {
    fontWeight: 'bold',
    marginBottom: '10px',
    [theme.breakpoints.up('lg')]: {
      fontWeight: 'inherit',
      marginBottom: 0,
    },
  },
});

function Availability(props) {
  const { data, classes } = props;
  const lastItem = data.length - 1;

  //const [crmDataSet, setCrmDataSet] = useState();
  //const [crmDataSetError, setCrmDataSetError] = useState(false);

  /*useEffect(() => {
    async function fetchData() {
      try {
        const asyncResponse = await axios
          .get(
            'http://192.168.1.240/SiteStream/WebService/ExportService.asmx/InitialExport'
          )
          .then(function(response) {
            var xml = response.data;
            parseString(xml, function(err, result) {
              setCrmDataSet(result);
            });
          })
          .catch(function(error) {
            setCrmDataSetError('true');
          });
      } catch (err) {
        console.error(err);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    // Get CRM Data
    //console.log(crmDataSet);
    if (crmDataSet && crmDataSet.Export) {
      console.log(crmDataSet.Export.SiteDetail[5].LocationID[0]._);
    }
  }, [crmDataSet]);
  */

  return (
    <Box className={classes.wrapper}>
      <Typography component="h2" variant="h2" align="center">
        Current Availability
      </Typography>
      <Spacer>
        <Box
          component={Grid}
          container
          className={classes.header}
          pb={2}
          display={{ xs: 'none', lg: 'flex' }}
        >
          <Grid item xs={3}>
            <Typography
              component="p"
              variant="subtitle2"
              className={classes.uppercase}
            >
              Home type
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              component="p"
              variant="subtitle2"
              className={classes.uppercase}
            >
              BEDS
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography
              component="p"
              variant="subtitle2"
              className={classes.uppercase}
            >
              PRICE & Availibility
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography
              component="p"
              variant="subtitle2"
              className={classes.uppercase}
            >
              DETAILS
            </Typography>
          </Grid>
        </Box>
        <div className="spaced-grid-wrapper">
          <Grid container spacing={2}>
            {data.map((item, key) => {
              if (item.home_type.homes_available) {
                return (
                  <React.Fragment key={key}>
                    <Grid item xs={12} md={4} lg={12}>
                      <Box
                        py={3}
                        px={[3, 3, 3, 0]}
                        className={`${
                          lastItem == key ? classes.lastItem : classes.item
                        }`}
                      >
                        <Grid container>
                          <Grid item xs={12} lg={3}>
                            <Typography
                              component="p"
                              variant="subtitle1"
                              className={`${classes.reduceLetterSpacing} ${classes.lowercase} ${classes.title}`}
                            >
                              {item.home_type.title[0].text}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} lg={2}>
                            <Typography
                              component="p"
                              variant="subtitle1"
                              className={classes.uppercase}
                            >
                              {item.home_type.number_of_bedrooms}
                              <Box
                                display={{ xs: 'inline', lg: 'none' }}
                                component="span"
                                className={`${classes.reduceLetterSpacing} ${classes.lowercase}`}
                              >
                                Bedrooms
                              </Box>
                            </Typography>
                          </Grid>
                          <Grid item xs={12} lg>
                            {item.home_type.price_from && (
                              <Typography
                                component="p"
                                variant="subtitle1"
                                className={`${classes.uppercase} ${classes.reduceLetterSpacing}`}
                              >
                                <Box
                                  component="span"
                                  className={classes.lowercase}
                                >
                                  From
                                </Box>{' '}
                                £
                                {parseInt(
                                  item.home_type.price_from
                                ).toLocaleString()}
                              </Typography>
                            )}
                            {item.home_type.available_plots &&
                              item.home_type.available_plots[0].text && (
                                <Typography
                                  component="p"
                                  variant="subtitle1"
                                  className={`${classes.reduceLetterSpacing} ${classes.lowercase}`}
                                >
                                  Homes -{' '}
                                  {item.home_type.available_plots[0].text}
                                </Typography>
                              )}
                          </Grid>
                          <Grid item xs={12} lg>
                            <Box mt={[4, 4, 4, 0]}>
                              <Link
                                to={linkResolver(item.home_type._meta)}
                                className={classes.link}
                              >
                                <Typography
                                  component="span"
                                  variant="subtitle1"
                                  className={`${classes.uppercase} ${classes.reduceLetterSpacing}`}
                                >
                                  HOME TYPE & FLOOR PLANS
                                </Typography>
                              </Link>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </React.Fragment>
                );
              }
            })}
          </Grid>
        </div>
      </Spacer>
    </Box>
  );
}

Availability.propTypes = {
  data: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Availability);
