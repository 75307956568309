import React from 'react';
import PropTypes from 'prop-types';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

function InlineImageGrid(props) {
  const { primary, data } = props;
  const logoMargin = data.length > 5 ? 0 : 4;
  return (
    <Container maxWidth={`${data.length > 5 ? 'xl' : 'lg'}`}>
      {primary.title && (
        <Typography component="h4" variant="h4" align="center">
          {primary.title[0].text}
        </Typography>
      )}
      <Box
        pt={2}
        display="flex"
        justifyContent="space-evenly"
        alignItems="center"
        flexWrap="wrap"
      >
        {data.map((item, key) => (
          <Box
            key={key}
            mx={logoMargin}
            syle={{ textAlign: 'center' }}
          >
            <img
              src={`${item.image.url}&q=10&lossless=1`}
              alt={item.image.alt}
              style={{ minWidth: '150px', maxWidth: '350px' }}
            />
          </Box>
        ))}
      </Box>
    </Container>
  );
}

InlineImageGrid.propTypes = {
  primary: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
};

export default InlineImageGrid;
