import axios from 'axios';
var parseString = require('xml2js').parseString;

const Lookup = () => {
  let endpoint =
    'http://192.168.1.240/SiteStream/WebService/ExportService.asmx/InitialExport';
  axios
    .get(endpoint)
    .then(function(response) {
      var xml = response.data;
      parseString(xml, function(err, result) {
        console.log(result);
        return result;
      });
    })
    .catch(function(error) {
      if (error.response) {
        console.log(error.response.headers);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log(error.message);
      }
      console.log(error.config);
      return [];
    });
};

export default Lookup;
