import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/styles';

const styles = {
  playerWrapper: {
    position: 'relative',
    width: '100%',
    paddingBottom: '66.67%', // Aspect ratio: 1200 / 1800 = 0.6667
    overflow: 'hidden',
  },
  reactPlayer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    border: 'none',
  },
};

function IframeEmbed(props) {
  const { data, classes } = props;

  return (
    <Container maxWidth="xl">
      <div className={classes.playerWrapper}>
        <iframe
          src={data.url[0].text}
          className={classes.reactPlayer}
          frameBorder="0"
          allowFullScreen
          title="Iframe"
        ></iframe>
      </div>
    </Container>
  );
}

IframeEmbed.propTypes = {
  data: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(IframeEmbed);
