import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import Logo from 'images/Hayfield-Wordmark-Reverse.svg';
import Ident from 'images/Hayfield-Ident-Reverse.svg';
import { ButtonOutlineLight } from 'components/ui';
import linkResolver from 'utils/linkResolver';

import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

const styles = theme => ({
  uppercase: {
    textTransform: 'uppercase',
  },
  bgImageWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '400px',
    padding: '40px 0px',
    height: 'auto',
    [theme.breakpoints.up('lg')]: {
      height: 'calc(100vh - 6vw)',
    },
  },
  indent: {
    width: '30px',
    [theme.breakpoints.up('lg')]: {
      width: '50px',
    },
  },
  logo: {
    width: '80px',
    [theme.breakpoints.up('lg')]: {
      width: '100px',
    },
  },
  socialicon: {
    color: theme.palette.text.white,
    fontSize: '2.5rem',
    transition: 'color 0.3s ease-in-out',
    '&:hover': {
      color: theme.palette.text.light,
    },
  },
});

export const Background = styled.div`
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: ${props => `url(${props.image})`};
`;

function LargeImageBlock(props) {
  const { data, classes } = props;

  const [backgroundImageUrl, setBackgroundImageUrl] = useState(data.image.url);

  useEffect(() => {
    const updateBackgroundImageUrl = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 480 && data.mobile_image1?.url) {
        return `${data.mobile_image1.url}&q=20&lossless=1&crop=center`;
      } else if (screenWidth < 480 && data.mobile_image?.url) {
        return `${data.mobile_image.url}&q=20&lossless=1&crop=center`;
      } else {
        return `${data.image.url}&q=10&lossless=1&crop=center&w=3000`;
      }
    };

    // Function to handle resize event
    const handleResize = () => {
      setBackgroundImageUrl(updateBackgroundImageUrl());
    };

    // Set initial background image
    setBackgroundImageUrl(updateBackgroundImageUrl());

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [data]);

  return (
    <Container maxWidth={false}>
      <Background
        className={classes.bgImageWrapper}
        image={`${backgroundImageUrl}&q=10&lossless=1`}
      >
        <Box>
          {data.show_logo_ident && <Ident className={classes.indent} />}
        </Box>
        <Box>
          <Box
            mb={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            {data.show_logo_text && <Logo className={classes.logo} />}
          </Box>
          <Box>
            {data.title && (
              <Typography
                component="p"
                variant="h2"
                align="center"
                color="secondary"
                className={classes.uppercase}
              >
                {data.title[0].text}
              </Typography>
            )}
            {data.show_social_icons && (
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
                mt={4}
              >
                <Box>
                  <a
                    href="https://www.facebook.com/hayfieldhomes"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FacebookIcon className={classes.socialicon} />
                  </a>
                </Box>
                <Box px={2}>
                  <a
                    href="https://www.instagram.com/hayfieldhomes"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <InstagramIcon className={classes.socialicon} />
                  </a>
                </Box>
                <Box>
                  <a
                    href="https://www.linkedin.com/company/hayfield-homes-official"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LinkedInIcon className={classes.socialicon} />
                  </a>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        <Box>
          {data.link_title && data.link && (
            <ButtonOutlineLight
              text={data.link_title[0].text}
              link={`${data.link._meta ? linkResolver(data.link._meta) : '/'}`}
            />
          )}
        </Box>
      </Background>
    </Container>
  );
}

LargeImageBlock.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object.isRequired,
};

export default withStyles(styles)(LargeImageBlock);
