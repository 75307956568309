import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import LogoBlue from 'images/Hayfield-Wordmark.svg';
import { RichText } from 'prismic-reactjs';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  body: {
    fontSize: '1rem',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
    },
  },
  logo: {
    width: '80px',
    [theme.breakpoints.up('lg')]: {
      width: '100px',
    },
  },
});

function TitleCaptionTwoColumnText(props) {
  const { classes, data, titlecomponent, xs, md, lg } = props;
  const xsVal = xs ? xs : 12;
  const mdVal = md ? md : 6;
  const lgVal = lg ? lg : 5;
  return (
    <Box>
      <Grid container justify="center">
        <Grid item xs={xsVal} md={mdVal} lg={lgVal}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            {data.show_hayfield_logo && (
              <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
                mb={2}
              >
                <LogoBlue className={classes.logo} />
              </Box>
            )}
            <Typography
              component={`${titlecomponent ? titlecomponent : 'h2'}`}
              variant={`${titlecomponent ? titlecomponent : 'h2'}`}
              color="textPrimary"
              gutterBottom
              align="center"
            >
              {data.title[0].text}
            </Typography>
            {data.caption && data.caption[0] && (
              <Box pt={2} pb={4}>
                <Typography
                  component="p"
                  variant="subtitle1"
                  color="textPrimary"
                  align="center"
                >
                  {data.caption[0].text}
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <div className="spaced-grid-wrapper">
            <Grid container justify="space-between">
              <Box component={Grid} item xs={12} md mb={{ xs: 4, md: 0 }}>
                <Typography
                  component="span"
                  variant="caption"
                  className={classes.body}
                >
                  <RichText render={data.text_left_column} />
                </Typography>
              </Box>
              <Grid item xs={1} />
              <Grid item xs={12} md>
                <Typography
                  component="span"
                  variant="caption"
                  className={classes.body}
                >
                  <RichText render={data.text_right_column} />
                </Typography>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
}

TitleCaptionTwoColumnText.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  logo: PropTypes.bool,
  titlecomponent: PropTypes.string,
  xs: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
};

export default withStyles(styles)(TitleCaptionTwoColumnText);
